import React from 'react';
import './style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PxContactArea from "../PxContactArea";

const PxLabDescription = (props) => {


    var settings = {
        dots: false,
        arrows: true,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7500,
        fade: true
    };


    return (
        <div className="PxLab-area">
            <div className="container">

                <div className="row">
                    <div className="col-lg-3 col-md-3 d-none d-lg-block">

                        <PxContactArea/>


                    </div>

                    <div className="col-lg-9 col-12">
                        <div className="PxTrend-item">
                            <h3>PxLab</h3>
                            <p>
                                Reliable product quality is indispensable for the long term success of your product. To
                                measure quality,
                                a variety of components such as sampling stations, pneumatic tubes, turntables, sample
                                preparation equipment
                                and analyzers are required. In a modern laboratory, automation software is responsible
                                for
                                orchestrating the measurement.
                                Most laboratory systems are tied to the vendor and therefore do not cooperate with
                                competitor equipment.
                                The laboratory is therefore severely limited in its choice of equipment.
                            </p>
                            <p>
                                PxLab is the open alternative to the manufacturer-bound systems. As an independent
                                automation software, PxLab welcomes
                                the combination of devices from different manufacturers and ensures smooth operation
                                between the systems.
                            </p>
                            <p>
                                Use our interactive web frontend to track, archive and report your measurements.
                                Configure
                                up to 20 sampling lines and optimize mixing ratios using the integrated mixing control.
                                Summarize lab activities with the integrated report engine or connect third party
                                software
                                via an ODBC interface.
                            </p>

                        </div>
                        <div className="pxtrend-slider">

                            <Slider {...settings}>

                                {
                                    props.slides.map((slide) =>

                                        <div className="slick-slide" key={slide.id}>
                                            <img className="slick-slide-image" src={slide.slide} alt={slide.id}/>
                                            <label className="slick-slide-label">{slide.description}</label>
                                        </div>
                                    )
                                }
                            </Slider>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}

export default PxLabDescription;
