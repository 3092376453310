import React from 'react';
import './style.css'
import Marquee from "react-fast-marquee";


import CALICA from "../../../images/pxClients/Calcia.png";
import CBR from "../../../images/pxClients/CBR.png";
import CEMENTA from "../../../images/pxClients/Cementa.png";
import ENCI from "../../../images/pxClients/ENCI.png";
import HANDSON from "../../../images/pxClients/hanson.png";
import INDOCEMENT from "../../../images/pxClients/Indocement.png";
import ISOVER from "../../../images/pxClients/Isover.png";
import ITALICEMENTI from "../../../images/pxClients/Italcementi.png";
import LEHGIGH from "../../../images/pxClients/Lehigh2.png";
import LHOIST from "../../../images/pxClients/Lhoist.png";
import MM from "../../../images/pxClients/M&M.png";
import NORCEM from "../../../images/pxClients/Norcem.png";
import REMONDIS from "../../../images/pxClients/Remondis.png";
import RHEINKALK from "../../../images/pxClients/Rheinkalk.png";
import TPI from "../../../images/pxClients/Tpi.jpg";
import TWIGA from "../../../images/pxClients/Twiga.png";
import YUGPK from "../../../images/pxClients/YUGPK.png";
import HEIDELBERGERCEMENT from "../../../images/pxClients/HeidelbergCement_Logo.png"
const source_mapping = {
    "CALICA": CALICA,
    "CBR": CBR,
    "CEMENTA": CEMENTA,
    "ENCI": ENCI,
    "HANDSON": HANDSON,
    "INDOCEMENT": INDOCEMENT,
    "ISOVER": ISOVER,
    "ITALICEMENTI": ITALICEMENTI,
    "LEHGIGH": LEHGIGH,
    "LHOIST": LHOIST,
    "M&M": MM,
    "NORCEM": NORCEM,
    "REMONDIS": REMONDIS,
    "RHEINKALK": RHEINKALK,
    "TPI": TPI,
    "TWIGA": TWIGA,
    "YUGPK": YUGPK,
    "HEIDELBERGERCEMENT": HEIDELBERGERCEMENT
}

const PxTrendClients = (props) => {

    return (
        <section className="clients">
            <div className="pXdivider">
                            <span className="title"> References </span>
            </div>
            {/*<div className="container">*/}
            {/*    <div className="col-12">*/}
                    <Marquee speed="30" direction={"right"} pauseOnHover={true} gradient={true}>
                        {
                            props.clients.map(client =>
                                <img key={client} className="pxClient" src={source_mapping[client]} alt={client}/>
                            )
                        }
                    </Marquee>
            {/*    </div>*/}

            {/*</div>*/}

        </section>
    )

}

export default PxTrendClients;