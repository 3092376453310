import React from 'react';
import {Link} from 'react-router-dom'

import './style.css'

const feature_records = [
    {
        name: "Crane Control",
        icon: "fi flaticon-claw-machine",
        link: "/pxCrane",
    },
    {
        name: "Data Management",
        icon: "fi flaticon-dashboard",
        link: "/pxTrend",
    },
    {
        name: "Lab Automation",
        icon: "fi flaticon-robotic-arm",
        link: "/pxLab",
    },
]

const Features = (props) => {

    return(
        <div className={`wpo-section-area`}>
            <div className="container">
                <div className="row">
                    {
                    feature_records.map(feat =>
                        <div key={feat.name} className="col-lg-4 col-md-4 d-none d-lg-block d-md-block ">
                            <Link to={feat.link}>
                            <div className="wpo-section-item">
                                <div className="wpo-section-icon">
                                    <i className={feat.icon} />
                                </div>
                                <div className="wpo-section-content">
                                    <p>{feat.name}</p>
                                </div>
                            </div>
                            </Link>
                        </div>
                            )
                        }
                </div>
            </div>
        </div>
     )
        
}

export default Features;
