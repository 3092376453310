import React, {useRef} from 'react';
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';
import './style.css'

import ModalVideo from "react-modal-video";
import Slider from "react-slick";
import pxLaptopWinCC from "../../../images/pxtrend/laptop/pxTrendLaptopAlarms.png";
import pxLaptopPlaybacks from "../../../images/pxtrend/laptop/pxTrendLaptopPlaybacks.png";
import pxLaptopGraphicBoard from "../../../images/pxtrend/laptop/pxTrendLaptopGraphicBoard.png";
import pxLaptopReports from "../../../images/pxtrend/laptop/pxTrendLaptopReports.png";
import PxTrendFeature from "./pxTrendFeature";



var settings = {
    dots: true,
    arrows: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    useCSS: true
};


const PxTrendFeatures = () => {

    let slider = useRef(null)

    const play = () => {
        slider.slickPlay();
    }
    const pause = () => {
        slider.slickPause();
    }

    const [open, setOpen] = React.useState(false);
    const [videoUrl, setVideoUrl] = React.useState('');

    const handleOpen = (url) => {
        pause()
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        play()
    };

    const vids = ["videos/PxAlarms.mp4", "videos/GraphicBoards.mp4", "videos/PxPlaybacksScreencast.mp4", "videos/PxTrendExcelPlugin.mp4"];
    const updateVideoUrl = (currentSlide) => {
        setVideoUrl(vids[currentSlide])
    }

    const onInitSetSlide = () => {
        setVideoUrl(vids[0]);
    }

    //

    return (
        <section className="px-features">

            <ModalVideo channel='custom' isOpen={open} url={videoUrl} onClose={handleClose}/>

            <Slider ref={s => (slider = s)} {...settings} afterChange={updateVideoUrl} onInit={onInitSetSlide}>


                <PxTrendFeature key="pxTrendFeature-Alarm" title="Alarms" img={pxLaptopWinCC}
                                description="Filter your control system alarms to an important subset. Analyze past events and
                                     find alarm patterns in your history." alt="WinCCAlarms" modalOpen={handleOpen}
                                url="videos/PxAlarms.mp4"/>

                <PxTrendFeature key="pxTrendFeature-Dashboards" title="Dashboards" img={pxLaptopGraphicBoard}
                                description="Customize your dashboards to show fill leves and production rates. Transform energy
                                     consumption metrics to simple on/off switches." alt="Dashboards"
                                modalOpen={handleOpen} url="videos/GraphicBoards.mp4"/>

                <PxTrendFeature key="pxTrend-Feature-Playbacks" title="Playbacks" img={pxLaptopPlaybacks}
                                description="Use existing plant sketches and bring them to live. Recreate any archived moment
                                        or monitor your plant status in real time." alt="Playbacks"
                                modalOpen={handleOpen} url="videos/PxPlaybackScreencast.mp4"/>

                <PxTrendFeature key="pxTrend-Feature-Reporting" title="Reporting Engine" img={pxLaptopReports}
                                description="Create, schedule and archive your reports. Generate reports with Excel or BIRT
                                 on demand or receive them daily via e-mail."
                                alt="PxTrendReports" modalOpen={handleOpen} url="videos/PxAlarms.mp4"/>

            </Slider>
        </section>
    )

}

export default PxTrendFeatures;