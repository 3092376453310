import React from 'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'
import FooterSection from '../../components/Footer'

import './style.css'

export const PrivacyNotice = () => {
    return (
        <div>
            <Navbar/>
            <Breadcumb bdtitle={'Privacy Notice'} bdsub={'Privacy Notice'}/>
            <br/>
            <br/>
            <div>
                <h3 align="center">Privacy Notice</h3>
                This privacy policy is intended to inform the users of this website about the nature, scope and purpose
                of the collection and use of personal data by the website operator Paranext Software GmbH. <br/> <br/>
                The website operator takes your privacy very seriously and treats your personal data confidentially and
                in accordance with the statutory provisions. As new technologies and the continuous development of this
                website may result in changes to this privacy policy, we recommend that you read through the privacy
                policy again at regular intervals. <br/><br/>
                Definitions of the terms used (e.g. "personal data" or "processing") can be found in Art. 4 DSGVO.
            </div>
            <br/>
            <div className="pn-server-logs">
                <h3 align="center">Server Logs</h3>
                We, the website operator or page provider, collect data about accesses to the website on the basis of
                our legitimate interest (see Art. 6 para. 1 lit. f. DSGVO) and store them as "server log files" on the
                website server. The following data is logged in this way:
                <br/> <br/>
                <ul>
                    <li>Visited website</li>
                    <li>Time at the time of access</li>
                    <li>Amount of data sent in bytes</li>
                    <li>Source/reference from which you came to the site</li>
                    <li>Browser used</li>
                    <li>Operating system used</li>
                    <li>IP address used</li>
                </ul>
                The server log files are stored for a maximum of 90 days and then deleted. The data is stored for
                security reasons, e.g. to be able to clarify cases of abuse. If data must be retained for evidentiary
                reasons, it is exempt from deletion until the incident has been finally clarified.

            </div>
            <br/>
            <div>
                <h3 align="center">Cookies, Analytics, Social Media</h3>
                We are not using cookies or other means to track our users.
            </div>
            <br/>


            <FooterSection/>
        </div>
    )
}

export const PrivacyNoticeLinkedIn = () => {
    return (
        <div>
            <Navbar/>
            <Breadcumb bdtitle={'Privacy Notice LinkedIn'} bdsub={'Privacy Notice LinkedIn'}/>
            <br/>
            <br/>
            <div>
                <h3 align="center">Privacy Notice</h3>
                The responsible party within the meaning of data protection laws, in particular the EU General Data Protection Regulation (GDPR), is: < br/>
                < br/>
                Paranext Software GmbH < br/>
                Dipl. Ing. Edgar Poth< br/>
                Petersweg 1 < br/>
                D-63179 Obertshausen < br/>
                Phone: +49 6104 973459 < br/>
                Email: info@paranext.de < br/>
            </div>
            < br/>
            <div className="pn-server-logs">
                <h3 align="center">Your data subject rights</h3>
                You can exercise the following rights at any time using the contact details provided by our data protection officer:
                <ul>
                    <li>Information about your data stored by us and its processing (Art. 15 GDPR)</li>
                    <li>Correction of incorrect personal data (Art. 16 GDPR)</li>
                    <li>Deletion of your data stored by us (Art. 17 GDPR)</li>
                    <li>Restriction of data processing if we are not yet allowed to delete your data due to legal obligations (Art. 18 GDPR)</li>
                    <li>objection to the processing of your data by us (Art. 21 GDPR)</li>
                    <li>Operating system used</li>
                    <li>Data portability, provided that you have consented to the data processing or have concluded a contract with us (Art. 20 GDPR)</li>
                </ul>
                If you have given us consent, you can revoke it at any time with effect for the future.
                You can submit a complaint to a supervisory authority at any time, e.g. to the competent supervisory authority in the federal state of your residence or to the authority responsible for us as the responsible body.
                You can find a list of the supervisory authorities (for the non-public sector) with address under: https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html.
            </div>
            <br/>
            <div className="pn-server-logs">
                <h3 align="center">Collection of general information when visiting our website</h3>
                Nature and purpose of the processing:
                When you access our website, i.e., when you do not register or otherwise submit information, information of a general nature is automatically collected. This information (server log files) includes, for example, the type of web browser, the operating system used, the domain name of your Internet service provider, your IP address and the like.
                In particular, they are processed for the following purposes:
                <ul>
                    <li>Ensuring a smooth connection of the website</li>
                    <li>ensuring a smooth use of our website</li>
                    <li>evaluating system security and stability</li>
                    <li>for the optimization of our website</li>
                </ul>
                We do not use your data to draw conclusions about your person. Information of this kind is statistically evaluated anonymously by us, if necessary, in order to optimize our Internet presence and the technology behind it.
            </div>
            <br/>
            <div>
                <h4 align="center">Legal basis and legitimate interest</h4>
                The processing is carried out in accordance with Art. 6 para. 1 lit. f GDPR on the basis of our legitimate interest in improving the stability and functionality of our website.
            </div>
            <br/>
            <div>
                <h4 align="center">Recipients</h4>
                Recipients of the data may be technical service providers who act as order processors for the operation and maintenance of our website.
            </div>
            <br/>
            <div>
                <h4 align="center">Third country transfer</h4>
                The collected data will not be transferred to third countries.
            </div>
            <br/>
            <div>
                <h4 align="center">Storage duration</h4>
                The data is deleted as soon as it is no longer required for the purpose for which it was collected. This is generally the case for data used to provide the website when the respective session has ended.
                In the case of storage of data in log files, this is the case after 14 days at the latest. Storage beyond this period is possible. In this case, the IP addresses of the users are anonymized, so that an assignment of the calling client is no longer possible.
            </div>
            <br/>
            <div>
                <h4 align="center">Provision prescribed or required</h4>
                The provision of the aforementioned personal data is neither legally nor contractually required. However, without the IP address, the service and functionality of our website is not guaranteed. In addition, individual services and services may not be available or may be limited. For this reason, an objection is excluded.
            </div>
            < br/>
            <div>
                <h3 align="center">Contact form via Linkedin</h3>
            </div>
            < br/>
            <div>
                <h4 align="center">Nature and purpose of processing:</h4>
                The data automatically inserted by Linkedin is stored for the purpose of individual communication with you. For this purpose, it is necessary to provide a valid e-mail address as well as your name (first and last name), job title and company name. These serve the assignment of the request and the subsequent response to the same. The provision of further data is optional.
            </div>
            < br/>
            <div>
                <h4 align="center">Legal basis</h4>
                The processing of the data entered in the contact form is based on a legitimate interest (Art. 6 para. 1 lit. f GDPR).
                By providing the contact form, we would like to enable you to contact us in an uncomplicated manner. The information you provide will be stored for the purpose of processing the request and for possible follow-up questions.
                If you contact us to request a quote, the data entered in the contact form will be processed to carry out pre-contractual measures (Art. 6 para. 1 lit. b GDPR).
            </div>
            < br/>
            <div>
                <h4 align="center">Recipients</h4>
                Recipients of the data are, if applicable, order processors.
            </div>
            < br/>
            <div>
                <h4 align="center">Third country transfer</h4>
                The collected data will not be transferred to third countries.
            </div>
            < br/>
            <div>
                <h4 align="center">Storage duration</h4>
                Data will be deleted no later than 6 months after processing the request.
                If a contractual relationship arises, we are subject to the statutory retention periods according to the German Commercial Code (HGB) and delete your data after these periods have expired.
            </div>
            < br/>
            <div>
                <h4 align="center">Provision prescribed or required</h4>
                The provision of your personal data is voluntary. However, we can only process your request if you provide us with your name, e-mail address and the reason for the request.
                SSL-Encryption
                To protect the security of your data during transmission, we use state-of-the-art encryption methods (e.g. SSL) via HTTPS.
            </div>
            < br/>
            <div>
                <h4 align="center">SSL-Encryption</h4>
                To protect the security of your data during transmission, we use state-of-the-art encryption methods (e.g. SSL) via HTTPS.
            </div>
            < br/>
            <div>
                <h3 align="center">Information about your right to object according to Art. 21 GDPR</h3>
            </div>
            < br/>
            <div>
                <h4 align="center">Individual right of objection</h4>
                You have the right to object at any time, on grounds relating to your particular situation, to the processing of personal data concerning you which is carried out on the basis of Art. 6(1)(f) GDPR (data processing on the basis of a balance of interests); this also applies to profiling based on this provision within the meaning of Art. 4 No. 4 GDPR.
                If you object, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing serves the assertion, exercise or defense of legal claims.
            </div>
            < br/>
            <div>
                <h4 align="center">Recipient of an opposition</h4>
                Paranext Software GmbH< br/>
                Dipl. Ing. Edgar Poth< br/>
                Petersweg 1< br/>
                D-63179 Obertshausen< br/>
                Phone: +49 6104 973459< br/>
                Email: info@paranext.de< br/>
            </div>
            < br/>
            <div>
                <h4 align="center">Change to our privacy policy</h4>
                We reserve the right to adapt this data protection declaration so that it always complies with the current legal requirements or in order to implement changes to our services in the data protection declaration, e.g. when introducing new services. The new privacy policy will then apply to your next visit.
            </div>
            < br/>
            <div>
                <h4 align="center">Questions to the data protection officer</h4>
                If you have any questions regarding data protection, please write us an e-mail or contact the person responsible for data protection in our organization directly:< br/>
                < br/>
                Dipl. Ing. Edgar Poth < br/>
                Phone: +49 6104 973459< br/>
                Email: info@paranext.de < br/>
                < br/>
                Die Datenschutzerklärung in deutscher Sprache wurde mithilfe der activeMind AG erstellt, den Experten für externe Datenschutzbeauftragte (Version #2020-09-30).
                The translation into English was done with the help of DeepL.
            </div>
            <FooterSection/>
        </div>
    )
}

export default PrivacyNotice;