import React from "react";
import './style.css'

const PxContactArea = () => {

    return (
        <div className="contact-us-area">
            <h3>Contact Us</h3>
            <div className="contact-s">
                <span><i className="contact-phone" aria-hidden="true"></i>Call Us</span>
                <p>Tel: +49 6104 973459</p>
            </div>
            <div className="contact-s">
                <span><i className="contact-mail" aria-hidden="true"></i>Email Us</span>
                <p>info@paranext.de</p>
            </div>
            <div className="contact-s">
                <span><i className="contact-location" aria-hidden="true"></i>Our Location</span>
                <p> Petersweg 1 <br/> D-63179 Obertshausen</p>
            </div>
        </div>
        )
}

export default PxContactArea;