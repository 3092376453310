import React, {Component} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './style.css'


class SimpleSlider extends Component {

    render() {
        var settings = {
            dots: false,
            arrows: true,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 7500,
            fade: true
        };

        return (
            <section className="hero hero-slider-wrapper hero-style-1">
                <div className="hero-slider">
                    <Slider {...settings}>

                        {
                            this.props.slides.map(slide =>
                                <div key={slide} className={slide + " slide"}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col col-lg-9 slide-caption">
                                                <h2><span>{this.props.slogan}</span></h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </Slider>
                </div>
            </section>
        );
    }
}

export default SimpleSlider;