import React from 'react';
import {Link} from 'react-router-dom'
import MobileMenu from '../../components/MobileMenu'
import logo from '../../images/logo/paranext_logo_gg.png'

import './style.css'

const Header = () => {

    return(
            <header>
                <div className="header-style-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-10 col-sm-10 col-8 col-t">
                                <div className="logo">
                                    <Link to="/"><img src={logo} alt=""/></Link>
                                </div>
                            </div>
                            <div className="col-lg-8 d-none d-lg-block col-m">
                                <div className="main-menu">
                                    <nav className="nav_mobile_menu">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li>Products
                                                <ul className="submenu">
                                                    <li><Link to="/pxtrend">PxTrend</Link></li>
                                                    <li><Link to="/pxlab">PxLab</Link></li>
                                                    <li><Link to="/pxcrane">PxCrane</Link></li>
                                                </ul>
                                            </li>
                                            <li><Link to="/partner">Partner</Link></li>
                                            <li><Link to="/contact">Contact</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-1 col-2">
                                <MobileMenu/>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
     )
        
}

export default Header;
