import React from  'react';
// components
import Navbar from '../../components/Navbar'
import PxPartnerDescription from "../../components/PxPartnerDescription";
import FooterSection from '../../components/Footer'
import SimpleSlider from "../../components/hero";
import Features from "../../components/features";


const PxPartner = () => {
    return(
       <div>
           <Navbar/>
           <SimpleSlider slides={['partner-slide']} slogan=""/>
           <Features/>
           <PxPartnerDescription/>
           <FooterSection/>
       </div>
    )
}

export default PxPartner;