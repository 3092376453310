import React from 'react';

import './style.css'

const Contactpage = () => {

    return(
        <div className="contact-page-area">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center contact-header">
                        <h2>Our Contacts</h2>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="contact-page-item">
                            {/*<h2>Our Contacts</h2>*/}
                            <div className="adress">
                                <h3>Address</h3>
                                <span> Paranext Software GmbH</span>
                                <span>Petersweg 1, D-63179 Obertshausen</span>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="contact-page-item">
                            <div className="phone">
                                <h3>Contacts</h3>
                                <span>Phone: +49 6104 973459</span>
                                <span>Email: info@paranext.de</span>
                                {/*<span>0-123-456-7890</span>*/}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="contact-page-item">
                            <div className="adress">
                                <h3>Legal Notice</h3>
                                <span>Managing Director: Dipl. Ing. Edgar Poth</span>
                                <span>Commercial register: Offenbach HRB 8340</span>
                                <span>VAT ID: DE114197039</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default Contactpage;
