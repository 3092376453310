import React from 'react';

import abus from '../../images/about/about_us.png'
import './style.css'

const AboutSection = () => {

    return (
        <div className="wpo-about-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-12 col-sm-12 order-lg-0 order-md-1 order-sm-1">
                        <div className="wpo-about-img">
                            <img src={abus} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-5 sec-p">
                        <div className="wpo-about-content">
                            <h2>About Us</h2>
                            <p> For more more than 25 years we have been developing software solutions for corporations from
                                all over the world. From the very beginning, we have seen maintainability and reliability
                                as key to the success of our products.</p>
                            <p>Today, two decades and hundreds of projects later, we continue to believe in these
                                values. Maybe now more than ever.</p>
                            <div className="row">
                                <div className="col-6">
                                    <div className="signature-section">
                                        <div className="si-text">
                                            <p>Edgar Poth</p>
                                            <span>Chief Executive Officer</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="signature-section">
                                        <div className="si-text">
                                            <p>Philippe Poth</p>
                                            <span>Product Owner PxTrend</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default AboutSection;
