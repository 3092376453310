import React from 'react';
import './style.css'

const PxTrendStatements = () => {
    return(
        <section className="statements">
            <div className="container">
                <div className="row sliding-keys">
                    <div className="fade-item-0 col-lg-4 col-md-4 col-sm-12">
                        <div className="fancy-item">
                            <div className="title">
                                Fast
                            </div>
                            Modern factories create a huge amount of data of various information types.
                            Handle thousands of alarms, events or counters per second.
                        </div>
                    </div>
                    <div className="fade-item-05 col-lg-4 col-md-4 col-sm-12">
                        <div className="fancy-item">
                            <div className="title">
                                Customizable
                            </div>
                            Group, filter and aggregate this flood of information to create insight.
                            Combine metrics and alarms to get the bigger picture.
                        </div>
                    </div>
                    <div className="fade-item-1 col-lg-4 col-md-4 col-sm-12">
                        <div className="fancy-item">
                            <div className="title">
                                Open
                            </div>
                            Whether you prefer Excel, MQTT, OPC UA or SQL - PxTrend allows high-performance access
                            to
                            all data via your preferred interface.
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default PxTrendStatements;