import React from 'react';
// components
import Navbar from '../../components/Navbar'
import PxCraneDescription from "../../components/PxCraneDescription";
import FooterSection from '../../components/Footer'

import pxCrane1 from '../../images/pxCrane/pxCrane3D.png'
import pxCrane2 from '../../images/pxCrane/crane02.jpg'
import SimpleSlider from "../../components/hero";
import Features from "../../components/features";

const pxTrendSlideFeatures = [
    {
        id: "crane-slide-1",
        slide: pxCrane1,
        description: "Visualize the crane bay in 3D to stay informed about the fill levels."
    },
    {
        id: "crane-slide-2",
        slide: pxCrane2,
        description: "Track the activity of the crane in real time, manage alerts and intercept or restart the automation process manually."
    }
]

const PxCrane = () => {
    return (
        <div>
            <Navbar/>
            <SimpleSlider slides={['pxCrane-slide']} slogan=""/>
            <Features/>

            <PxCraneDescription slides={pxTrendSlideFeatures}/>

            <FooterSection/>
        </div>
    )
}

export default PxCrane;