import React from 'react';
import './style.css'
import {isIE} from 'react-device-detect';

const PxTrendFeature = (props) => {

    const key = "pxTrend_" + props.title

    return (
        <section className="dark-blue" key={"pxTrend" + props.title}>
            <div className="container">
                <div className="row introduction-area">

                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="introduction-picture">
                            <img src={props.img} alt={props.alt}/>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="statement">
                            <div className="title">{props.title}</div>
                            {props.description}

                            <div className="px-button-wrapper">
                                {isIE? <div/> :
                                <div className="px-feature-button" id={key} onClick={() => props.modalOpen(key)}>
                                    In Action
                                </div>}

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section>
    )

}

export default PxTrendFeature;