import React from  'react';
// components
import Navbar from '../../components/Navbar'
import PxTrendDescription from '../../components/PxTrendDescription'
import FooterSection from '../../components/Footer'
import SimpleSlider from "../../components/hero";
import Features from "../../components/features";

const PxTrend = () => {
    return(
       <div>
           <Navbar/>
           <SimpleSlider slides={['pxTrend-slide']} slogan=""/>
           <Features />
           <PxTrendDescription/>
           <FooterSection/>
       </div>
    )
}

export default PxTrend;