import React from 'react';
import './style.css'

const PxTrendCentralServer = () => {

    return (
        <section className="achievements">
            <div className="pXdivider">
                            <span className="title">
                                Stay Connected
                            </span>
            </div>
            <div className="container">
                <div className="row achievements-area">
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        {/*order-lg-0 order-md-1 order-sm-1">*/}
                        <div className="central-server-statement">
                            <div className="title">Central Server</div>
                            <div>
                                Monitor regional performance and create consistent reports on group level by linking
                                your existing PxTrend systems. <br/><br/>
                                Our central server technology acts as a single point of entry, giving you real-time
                                access to all underlying systems.
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-0"/>
                    <div className="col-lg-7 col-md-12 col-sm-12">

                        <div className="mapbox-logo-container" title="Central Servers utilized to cover regions">

                            <a href="http://mapbox.com/about/maps" className='mapbox-logo' target="_blank"
                               rel="noreferrer">Mapbox</a>
                            <div className="mapbox-attribution-container">
                                <a href="https://www.mapbox.com/about/maps/">© Mapbox | </a>
                                <a href="http://www.openstreetmap.org/copyright">© OpenStreetMap | </a>
                                <a href="https://www.mapbox.com/map-feedback/" target="_blank" rel="noreferrer"><strong>Improve
                                    this
                                    map</strong></a>
                            </div>
                        </div>

                        <div className="text-center">
                            Three central servers utilized by one client to connect over 50 plants world wide.
                        </div>

                    </div>

                </div>
            </div>
        </section>
    )

}

export default PxTrendCentralServer;