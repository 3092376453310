import React from 'react';

import './style.css'

const ServiceArea = ( props ) => {

    return(
        <div className="wpo-service-s2">
            <div className="container">
                <div className="col-l2">
                    <div className="wpo-section-title text-center">
                        <span>Innovative, Intuitive, Reliable</span>
                        <h2>Products & Services</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fi flaticon-dashboard"/>
                                </div>
                                <h2>Data Management</h2>
                                {/*<p>PxTrend provides fast access to any historical or real time process related information.</p>*/}
                                <p> <b>PxTrend</b> provides fast access to historical metrics, alarms, events and quality data.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fi flaticon-claw-machine"/>
                                </div>
                                <h2>Crane Control</h2>
                                <p>With <b>PxCrane</b> it is easy to automate and optimize complex crane setups using an intuitive web frontend. </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fi flaticon-robotic-arm"/>
                                </div>
                                <h2>Lab Automation</h2>
                                <p>Upgrade and automate labs with multi vendor equipment using our independent solution: <b>PxLab</b></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fi flaticon-technical-support"/>
                                </div>
                                <h2>Managed Service</h2>
                                <p>All our products can be acquired as a managed service. We ensure operations on-prem or in the cloud.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fi flaticon-settings-1"/>
                                </div>
                                <h2>Consulting</h2>
                                <p>Accelerate your projects by leveraging our expertise in search algorithms and software development.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fi flaticon-customization"/>
                                </div>
                                <h2>Custom Solutions</h2>
                                <p>Envision a product tailored for your needs? We create software to solve your specific problem.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default ServiceArea;
