import React from 'react';
import './style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import PxContactArea from "../PxContactArea";

const PxPartnerDescription = () => {

    return (
        <div className="PxCrane-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-12">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <PxContactArea/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="PxTrend-item">
                            <h3>Our Partner Network</h3>
                            <p>
                                We are very fortunate to have such reliable partners on our side. <br/>
                                Since not all partners distribute the whole range of our products, feel free to contact
                                us and we will refer you to a suitable partner.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="partner-logo">
                                    <div className="pxPartner-kmi">
                                        <a href="http://www.kmi.info" target="_blank" rel="noreferrer">
                                            <span className="link-spanner"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="partner-logo">
                                    <div className="pxPartner-psa">
                                        <a href="http://www.psa-automation.com" target="_blank" rel="noreferrer">
                                            <span className="link-spanner"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="partner-logo">
                                    <div className="pxPartner-tec">
                                        <a href="https://www.technord.com/en/" target="_blank" rel="noreferrer">
                                            <span className="link-spanner"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="partner-logo">
                                    <div className="pxPartner-pv">
                                        <a href="http://www.profi-vision.com" target="_blank" rel="noreferrer">
                                            <span className="link-spanner"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="partner-logo">
                                    <div className="pxPartner-dal">
                                        <a href="http://www.dalapc.com" target="_blank" rel="noreferrer">
                                            <span className="link-spanner"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="partner-logo">
                                    <div className="pxPartner-xapi">
                                        <a href="http://www.xapi.de" target="_blank" rel="noreferrer">
                                            <span className="link-spanner"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="partner-logo">
                                    <div className="pxPartner-pas">
                                        <a href="https://pa-ats.com/de/" target="_blank" rel="noreferrer">
                                            <span className="link-spanner"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default PxPartnerDescription;
