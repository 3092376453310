import React from 'react';
import './style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PxContactArea from "../PxContactArea";

const PxCraneDescription = (props) => {

    var settings = {
        dots: false,
        arrows: true,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7500,
        fade: true
    };


    return (
        <div className="PxCrane-area">
            <div className="container">

                <div className="row">
                    <div className="col-lg-3 col-md-3 d-none d-lg-block">

                                <PxContactArea/>
                                {/*<PxScreenCasts casts={casts} handleModalOpen={handleOpen}/>*/}

                    </div>
                    <div className="col-lg-9 col-12">
                        <div className="PxTrend-item">
                            <h3>PxCrane</h3>
                            <p>
                                The implementation of complex process logics for bulk material crane systems on PLC level is complex and not very flexible.
                                A subsequent automation of existing plants is often not provided by the manufacturers or simply not possible.
                            </p>
                            <p>
                                PxCrane takes a different approach. As a high level component, PxCrane takes over the complex process logic and communicates it to the PLC in a series of simple commands.
                                Consequently even older systems can be automated without much effort.
                            </p>
                            <p>
                                Configure, adapt and control your crane automation from your browser using our interactive web front end.
                                Visualize your fill levels as 3D graphics or heatmaps. Monitor the current activity and manually take over
                                the crane if necessary.
                            </p>

                        </div>

                        <div className="pxtrend-slider">

                            <Slider {...settings}>

                                {
                                    props.slides.map((slide) =>

                                        <div className="slick-slide" key={slide.id}>
                                            <img className="slick-slide-image" src={slide.slide} alt={slide.id}/>
                                            <label className="slick-slide-label">{slide.description}</label>
                                        </div>
                                    )
                                }
                            </Slider>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default PxCraneDescription;
