import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Homepage from '../HomePage'
import PxPartner from '../PxPartner'
import PxTrend from '../PxTrend'
import PxLab from "../PxLab";
import PxCrane from "../PxCrane";
import ContactPage from '../ContactPage'
import {PrivacyNotice, PrivacyNoticeLinkedIn} from '../PrivacyNotice';

const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
          <Switch>
              <Route exact path='/' component={Homepage} />
              <Route path='/home' component={Homepage} />
              <Route path='/partner' component={PxPartner} />
              <Route path='/pxTrend' component={PxTrend} />
              <Route path='/pxLab' component={PxLab} />
              <Route path='/pxCrane' component={PxCrane} />
              <Route path='/contact' component={ContactPage} />
              <Route path='/privacy-notice' component={PrivacyNotice} />
              <Route path='/privacy-notice-linkedin' component={PrivacyNoticeLinkedIn} />
          </Switch>
      </Router>
      
    </div>
  );
}

export default AllRoute;
