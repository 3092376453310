import React from 'react';
import './style.css'

import PxTrendHeader from "./pxTrendHeader";
import PxTrendFeatures from "./pxTrendFeatures";
import PxTrendStatements from "./pxTrendStatements";
import PxTrendCentralServer from "./pxTrendCentralServer";
import PxTrendClients from "./PxTrendClients";

const PxTrendDescription = (props) => {

    return (
        <div className="PxTrend-area">
            <PxTrendHeader/>
            <PxTrendStatements/>
            <PxTrendFeatures/>
            <PxTrendCentralServer/>
            <PxTrendClients
                clients={["CALICA", "CBR", "CEMENTA", "ENCI", "HANDSON", "INDOCEMENT", "ISOVER",
                    "ITALICEMENTI", "LEHGIGH", "LHOIST", "M&M", "NORCEM", "REMONDIS", "RHEINKALK",
                    "TPI", "YUGPK"]}/>
        </div>
    )
}


export default PxTrendDescription;
