import React from  'react';

import Navbar from '../../components/Navbar'
import SimpleSlider from '../../components/hero'
import AboutSection from '../../components/about'
import ServiceArea from '../../components/ServiceArea'
import CounterSection from '../../components/CounterSection'
import FooterSection from '../../components/Footer'
import Features from "../../components/features";


const Homepage = () => {
    return(
       <div>
           <Navbar/>
           <SimpleSlider slides={['pxTrend-slide','pxCrane-slide','pxLab-slide']} slogan=""/>
           <Features />
           <AboutSection/>
           <CounterSection/>
           <ServiceArea/>
           <FooterSection/>
       </div>
    )
}

export default Homepage;