import React from 'react';

const PxTrendHeader = () => {
    return(
        <section className="header">
            <div className="pXcontainer">
                <div className="row">
                    <div className="section-title text-center col-12">
                        <span>Connecting the Dots</span>
                        <h2> PxTrend </h2>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default PxTrendHeader;