import React from 'react';
import {Link} from 'react-router-dom'

import './style.css'


const CounterSection = (props) => {

    return (
        <div className={`wpo-counter-area ${props.subclass}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-9 col-sm-12">
                        <div className="wpo-counter-content">
                            <h2>Business History in Short</h2>
                            <p> Paranext was founded in 1992 as an IT consulting company and initially focused on
                                developing custom solutions. In the early 2000s, after seeing that several customers
                                were facing the same challenges, we decided to expand our services and offer our own
                                solutions. For more than a decade, we have continuously improved our products and
                                implemented data management, crane and laboratory automation solutions in more than 30
                                countries with our partners.
                            </p>
                            <div className="btns">
                                <div className="btn-style btn-style-3"><Link to="/partner">Learn More About Our
                                    Partners...</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CounterSection;
