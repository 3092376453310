import React from  'react';
// components
import Navbar from '../../components/Navbar'


import PxLabDescription from "../../components/PxLabDescription";

import FooterSection from '../../components/Footer'

import pxLabRobot from '../../images/pxLab/pxlab01.jpg'
import pxLabUnionBridge from '../../images/pxLab/union_bridge-2294.jpg'

import SimpleSlider from "../../components/hero";

import Features from "../../components/features";

const pxLabFeatureSlides=[
    {
        slide: pxLabRobot,
        description: "With the aid of the intuitive conceptual representation of the laboratory, you can see at a glance what is going on in the laboratory. "
            // "The visualization contains information about the sampling points still available, the sampling line currently in use, " +
            // "the target of the current process step and the status of the measuring instruments involved.",
    },
    {
        slide: pxLabUnionBridge,
        description: "Measurement process in action ©HeidelbergCement, Union Bridge",
    }
]

const PxLab = () => {
    return(
       <div>
           <Navbar/>
           <SimpleSlider slides={['pxLab-slide']} slogan=""/>
           <Features/>
           <PxLabDescription slides={pxLabFeatureSlides}/>
           <FooterSection/>
       </div>
    )
}

export default PxLab;