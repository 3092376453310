import React from 'react';
import {Link} from 'react-router-dom'

import './style.css'

const FooterSection = () => {

    return(
        <div className="wpo-footer-area">
            <div className="wpo-footer-bottom">
                <div className="container">
                    <div className="wpo-footer-bottom-content">
                        <div className="row">
                            <div className="col-4">
                                <div className="footer-link">

                                    <Link to="/contact">Impressum</Link>

                                </div>
                            </div>
                            <div className="col-4">
                                <span>© Paranext Software GmbH</span>
                            </div>
                            <div className="col-4">
                                <div className="footer-link">

                                    <Link to="/privacy-notice">Privacy Notice</Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default FooterSection;
